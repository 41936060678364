import styled from "styled-components";
import * as React from "react";
import {
  IndexHeroSegment,
  DeepUnderstandingSegment,
  StopForgettingSegment,
  InsightsSegment,
} from "../components/PageSegments";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { BasicLayout } from "../layouts/BasicLayout";
import { track_homepage_visit } from "../services/mixpanel/mixpanel";
import { mobileSize } from "../util/variables";
import { Link } from "gatsby";
import { TopBanner } from "../components/ui/TopBanner";
import { StickyNavBar } from "../components/ui/StickyNavBar";

export default () => {
  React.useEffect(() => {
    track_homepage_visit();
  }, []);

  return (
    <BasicLayout>
      <Main>
        <TopBanner show fixed={false}>
          <BannerText>
            🏆 Winner of Google’s <b>App of the Year!</b> <MobileBr />Celebrate with{" "}
            <Link to="/onboarding/questions">20% off.</Link>
          </BannerText>
        </TopBanner>
        {/* <NavBar /> */}
        <StickyNavBar />
        <IndexHeroSegment />
        <DeepUnderstandingSegment />
        <StopForgettingSegment />
        <InsightsSegment />
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
